import styled from 'styled-components';
export const AppStyled = styled.div`
  &.App {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    .rootContainer,
    .headerContainer {
      width: 100%;
    }
  }
  @media (min-width: 1891px) {
    &.App {
      .rootContainer,
      .headerContainer,
      .v-subCategory {
        max-width: ${props =>
          `${!props.props.isVerticalAds ? `100%` : `calc(100% - 160px * 2 - 15px * 4)`}`};
      }
    }
    .App {
      &__leftAd,
      &__rightAd {
        display: ${props => (props.props.isVerticalAds ? 'flex' : 'none')};
        width: 160px;
        margin: 80px 15px;
        height: auto;
        color: #8c8c8c;
        /* background-color: #dadada; */
      }
    }
  }
`;
