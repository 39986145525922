import { lazy } from 'react';
import Loadable from 'react-loadable';
import React from 'react';

const lazyWithRetry = componentImport =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
    );
    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }
      throw error;
    }
  });

const componentLoader = (componentImport, attemptsLeft = 3) =>
  lazy(async () => {
    try {
      const component = await componentImport();
      return component;
    } catch (error) {
      let timer = setTimeout(() => {
        clearTimeout(timer);
        if (attemptsLeft === 1) {
          throw error;
        }
        componentLoader(componentImport, attemptsLeft - 1);
      }, 1500);
    }
  });

export { lazyWithRetry, componentLoader };
