import * as actionTypes from './actionTypes';

const initialState = {
  paymentMethod: [],
};

const payperview = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    default:
      return state;
  }
};

export default payperview;
