import { tenantEnvConfig } from '../../constants/tenantConfig/tenantEnvConfig';
import { tenantStrings } from '../../constants/tenantConfig/tenantStrings';
import { tenantFeatureFlags } from '../../constants/tenantConfig/tenantFeatureFlags';
import { integrationConfigs } from '../../constants/tenantConfig/integrationConfigs';
import { getDeepProp } from '../../utils/utils';
import { REACT_APP_SSR } from '~constants/envConfig';

let currentLanguage = '';
const rootState = JSON.parse(window.localStorage.getItem('persist:root') || '{}');

if (rootState && rootState.root) {
  currentLanguage = JSON.parse(rootState.root).language;
}

export const storeSubscribe = store => {
  const state = store.getState();
  if (currentLanguage !== state.root.language) {
    currentLanguage = state.root.language;
  }
};

export const tenantConfig = {
  _ssr: REACT_APP_SSR,
  ...tenantEnvConfig,
  ...tenantStrings,
  features: {
    ...tenantFeatureFlags,
  },
  integrations: {
    ...integrationConfigs,
  },
};

export const getConfigByKey = (name = '') => {
  const configs = tenantConfig;
  const value = getDeepProp(configs, name, currentLanguage) || '';
  return value;
};
