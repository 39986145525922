import FETCH from '../utils/fetch';
import * as APIString from '../constants/apiString';
import queryString from 'query-string';
import { getDeviceResolution, getAccessToken } from '~root/utils/utils';
import { getConfigByKey } from '~features/tenantConfig/services';

export { getDetail, viewDetail, getSeason };

export const CONTENT_DETAIL_SELECT_FIELDS = [
  'can_preview',
  'category',
  'category_slug',
  'content_categories',
  'genre',
  'has_free_content',
  'id',
  'group',
  'images',
  'is_new_release',
  'is_premium',
  'long_description',
  'restriction',
  'num_first_episode_preview',
  'people',
  'provider_slug',
  'regions',
  'release_date',
  'released_episode_count',
  'runtime',
  'seasons',
  'short_description',
  'slug',
  'title',
  'total_episodes',
  'episode',
  'trailers',
  'type',
  'video_source',
  'subtitles',
  'price',
  'is_ads_disabled_when_playing',
  'metadata',
  'short_title',
  'duration',
  'payment_type',
  'film_code',
  'production_company_name',
];

export const CONTENT_DETAIL_SEASON_SELECT_FIELDS = ['id', 'slug', 'title'];
export const CONTENT_METADATA = ['peoples', 'genres', 'regions'];

export const CONTENT_DETAIL_TRAILER_SELECT_FIELDS = [
  'slug',
  'id',
  'images',
  'is_new_release',
  'title',
  'geo_blocked',
  'link_play',
];

export const CONTENT_VIEWS_SELECT_FIELDS = [
  'current_season',
  'id',
  'slug',
  'is_watchable',
  'progress',
  'youtube_video_id',
  'link_play',
  'play_info',
  'payment_infors',
  'is_favorite',
  'geo_blocked',
  'allowed_countries',
];

export const CONTENT_SEASON_SELECT_FIELDS = [
  'episodes',
  'id',
  'slug',
  'title',
  'is_new_release',
  'geo_blocked',
];

export const CONTENT_SEASON_ITEMS_SELECT_FIELDS = [
  'id',
  'slug',
  'title',
  'video_source',
  'is_premium',
  'is_new_release',
  'images',
  'can_preview',
  'episode',
  'type',
  'is_watchable',
  'short_title',
  'geo_blocked',
];

function getDetail(id) {
  const select = JSON.stringify({
    Content: CONTENT_DETAIL_SELECT_FIELDS,
    SeasonList: CONTENT_DETAIL_SEASON_SELECT_FIELDS,
    TrailerSerializerDocumentation: CONTENT_DETAIL_TRAILER_SELECT_FIELDS,
  });
  let data = { select, ...getDeviceResolution() };

  const queryParams = queryString.stringify(data);

  let authorization = getAccessToken() || '';

  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${id}/detail?${queryParams}`;
  return FETCH(path, 'GET', {
    authorization,
  });
}

function viewDetail(id) {
  let slugID = APIString.ROOT_PAGE_ID;
  if (id) {
    slugID = id;
  }

  const select = JSON.stringify({
    Content: [
      ...CONTENT_VIEWS_SELECT_FIELDS,
      getConfigByKey('ui_theme') === 'sctv' ? 'drm_session_info' : '',
    ],
  });
  let data = { select };

  const queryParams = queryString.stringify(data);

  let authorization = getAccessToken() || '';
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${slugID}/view?${queryParams}`;
  return FETCH(path, 'GET', {
    authorization,
  });
}

function getSeason(id) {
  const select = JSON.stringify({
    SeasonDetail: CONTENT_SEASON_SELECT_FIELDS,
    Content: CONTENT_SEASON_ITEMS_SELECT_FIELDS,
  });
  let data = { select, ...getDeviceResolution() };
  const queryParams = queryString.stringify(data);

  let authorization = getAccessToken(false) || '';

  const path = `/tenants/${APIString.TENANT_SLUG}/seasons/${id}/?${queryParams}`;
  return FETCH(path, 'GET', {
    authorization,
  });
}

export function getPickRibbonRelative(id, params = {}) {
  let authorization = getAccessToken(false) || '';
  const queryParams = queryString.stringify(params);
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${id}/related_contents/?${queryParams}`;
  return FETCH(path, 'GET', {
    authorization,
  });
}
