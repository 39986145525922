import { tenantEnvConfig } from './tenantEnvConfig';
import * as apiString from '../apiString';

const integrationConfigsByTenantSlug = {
  // PLEASE READ:
  // -  For each type of integration, we use the integration name as key (gtm, firebase, mux),
  //    then the config of the integration will be set into inner field.
  // -  If the integration is not available for the tenant, EXPLICITLY set undefined to the integration name.

  // Default for other tenants
  default: {
    gtm: undefined,
  },

  odv: {
    gtm: {
      id: 'GTM-PS68CSP',
    },
    firebase: {
      apiKey: apiString.REACT_APP_FIREBASE_APIKEY,
      authDomain: apiString.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: apiString.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: apiString.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: apiString.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: apiString.REACT_APP_FIREBASE_MESSAGIN_SENDER_ID,
      appId: apiString.REACT_APP_FIREBASE_APP_ID,
      measurementId: apiString.REACT_APP_FIREBASE_MEASUREMENT_ID,
    },
    onesignal: {
      app_id: apiString.REACT_APP_ONESIGNAL_APP_ID,
      safari_web_id: apiString.REACT_APP_ONESIGNAL_SAFARI_WEB_ID,
    },
    cookielaw: {
      data_domain_script: apiString.REACT_APP_DATA_DOMAIN_SCRIPT,
    },
    invite_referrals: {
      bid_e: apiString.REACT_APP_REFERRALS_ENCRYPTED_KEY,
      bid: apiString.REACT_APP_REFERRALS_BRAND_ID,
    },
    loginGoogle: {
      ggId: apiString.REACT_APP_GG_CLIENT_ID,
    },
    optimizeGg: {
      analyticsExperimentID: 'ydoNQaLlT86IHWtNiYUnFw',
      analyticsMeasurementId: '',
      idContainer: 'OPT-KT8XHBB',
    },
  },

  sctv: {
    gtm: undefined,
  },

  projectw: {
    gtm: {
      id: 'GTM-5H7QTGR',
    },
    bootpay: {
      applicationIdTest: '59a4d323396fa607cbe75de4',
    },
    loginGoogle: {
      ggId: '140840837237-2h25ai7l82crmgjf0dkhlak3sk4ifnil.apps.googleusercontent.com',
    },
  },
  escondido: {
    gtm: undefined,
  },
};

export const integrationConfigs =
  tenantEnvConfig.tenant_slug in integrationConfigsByTenantSlug
    ? integrationConfigsByTenantSlug[tenantEnvConfig.tenant_slug]
    : integrationConfigsByTenantSlug['default'];
