import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import FooterV2 from '../../footer/footerV2';
import styled from 'styled-components';
import { checkGeoBlocking } from '../../../app/services';
import { getConfigByKey, tenantConfig } from '../../tenantConfig/services';
import { setUserProperties } from './../../../services/analytics/SetUserProperties';
import { Link } from 'react-router-dom';
import { hideMenu, showMenu } from '~components/global/globalServices';
class BlockCountry extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.myInterval = null;
    this.state = {
      logo: getConfigByKey('nav_bar_logo'),
    };
  }
  componentDidMount() {
    const { blockRegionSubcription } = this.props;
    if (blockRegionSubcription) {
      return;
    }
    this._checkBlock();
  }

  componentWillMount() {
    const { dpHideMenu } = this.props;
    dpHideMenu();
  }

  componentWillUnmount() {
    const { dpShowMenu } = this.props;
    dpShowMenu();
  }

  componentWillReceiveProps(nextProps) {
    const { logo } = this.state;
    if (!logo && getConfigByKey('nav_bar_logo')) {
      this.setState({
        logo: getConfigByKey('nav_bar_logo'),
      });
    }
  }

  _checkBlock() {
    const { dispatchCheckGeoBlocking } = this.props;
    return;
    dispatchCheckGeoBlocking()
      .then(res => {
        let timer = setTimeout(() => {
          window.location.href = '/';
          clearTimeout(timer);
        }, 1000);
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  render() {
    const { t, blockRegionSubcription, language } = this.props;
    const { logo } = this.state;
    let text = {
      txtDescription: blockRegionSubcription
        ? t('box.txtBlockContrySubcription')
        : t('box.sorry', {
            site_name: getConfigByKey('site_name'),
            tenantName: getConfigByKey('name'),
          }),
    };
    return (
      <div style={Classes.background}>
        <RowStyled style={{ height: '100%', display: 'flex', paddingBottom: '5rem' }}>
          <Link className="v-block--logo" to={`/${language}/`} onClick={() => setUserProperties()}>
            <img src={logo} />
          </Link>
          <Col className="v-block--col" sm={12} style={{ ...Classes.block_container }}>
            <p className="v-block--title" style={{ fontWeight: 'bold' }}>
              {getConfigByKey('site_name')}
            </p>
            <p className="v-block--description">
              {t('box.unlimited movies')} {t('box.ondeman', { sologan: getConfigByKey('sologan') })}{' '}
              {t('box.tvShow')}
            </p>
            <p className="v-block--sr" style={{ textTransform: 'none', fontStyle: 'Italic' }}>
              {text.txtDescription}
            </p>
            <div className="v-block--footer" style={{ textTransform: 'none' }}>
              <p>{t('subscriptions.footer.ContactUs')}</p>
              <div
                style={{
                  cursor: 'pointer',
                  color: '#2574d4',
                  display: !getConfigByKey('phone_number_1') && 'none',
                }}
              >
                <i className="fa fa-phone" aria-hidden="true" style={{ color: '#8a8a8a' }}></i>
                <span style={{ color: '#8a8a8a' }}> : </span>
                {getConfigByKey('phone_number_1')}
              </div>
              <div
                style={{
                  color: '#2574d4',
                  display: !getConfigByKey('phone_number_2') && 'none',
                }}
              >
                <i className="fa fa-comment" aria-hidden="true" style={{ color: '#8a8a8a' }}>
                  {' '}
                  :{' '}
                </i>{' '}
                {getConfigByKey('phone_number_2')}
              </div>
              <div style={{ cursor: 'pointer', color: '#2574d4' }}>
                <i className="fa fa-envelope" aria-hidden="true" style={{ color: '#8a8a8a' }}>
                  {' '}
                  :{' '}
                </i>{' '}
                {t('subscriptions.footer.email', {
                  email: getConfigByKey('email'),
                })}
              </div>
            </div>
          </Col>
          {getConfigByKey('features.bottom_bar_information', true) && (
            <div className="v-block--adress">
              <p>{getConfigByKey('address.copyright')}</p>
              <p>{getConfigByKey('address.copyrightV2')}</p>
              <p>{getConfigByKey('companyReg')}</p>
              <p>{getConfigByKey('address.address')}</p>
              <p>{getConfigByKey('address.state')}</p>
            </div>
          )}
          <Link
            className="v-block--home"
            style={{ display: !blockRegionSubcription && 'none' }}
            to={`/${language}/`}
            onClick={() => setUserProperties()}
          >
            <i className="fa fa-angle-double-left" /> {t('box.txtHome')}
          </Link>
        </RowStyled>
      </div>
    );
  }
}

const Classes = {
  block_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    flexDirection: 'column',
  },
  background: {
    backgroundImage: `url(${require('../../../assets/img/background1.jpeg')})`,
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    textTransform: 'uppercase',
    fontFamily: 'Open sans',
    height: '100%',
  },
};

const RowStyled = styled.div`
  &.v-block {
  }
  .v-block {
    &--logo {
      object-fit: cover;
      position: absolute;
      top: 19px;
      left: 15px;
      z-index: 9;
      img {
        max-width: 7rem;
      }
    }
    &--title {
      font-size: 3.5rem;
      text-align: center;
    }
    &--description {
      font-size: 1.5rem;
      text-align: center;
    }
    &--sr {
      font-size: 1.25rem;
      text-align: center;
    }
    &--col {
      width: 100%;
    }
    &--footer {
      border-top: 0.5px solid #666666;
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      text-align: center;
      position: fixed;
      bottom: 2rem;
      width: 80%;
      p {
        padding: 0 0;
      }
      div {
        padding-right: 0;
        margin-bottom: 0.5rem;
      }
      font-size: 1rem;
    }
    &--adress {
      text-align: right;
      position: fixed;
      color: #999999;
      right: 27px;
      display: none;
      bottom: 27px;
      text-transform: capitalize;
      font-size: 1rem;
    }
    &--home {
      position: fixed;
      color: #fff;
      left: 15px;
      bottom: 27px;
      text-transform: capitalize;
      font-size: 1.25rem;
      display: none;
      :hover {
        text-decoration: none;
      }
    }
  }
  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    .v-block {
      &--logo {
        left: 45px;
        img {
          max-width: 11rem;
        }
      }
      &--title {
        font-size: 5.5rem;
      }
      &--description {
        font-size: 2rem;
      }
      &--sr {
        font-size: 1.75rem;
      }
      &--footer {
        display: flex;
        text-transform: none;
        flex-direction: row;
        position: relative;
        bottom: 0;
        width: auto;
        p {
          padding: 0 1.5rem;
        }
        div {
          padding-right: 1.5rem;
          margin-bottom: 0rem;
        }
        font-size: 1rem;
      }
      &--adress {
        display: block;
      }
      &--home {
        left: 45px;
        display: block;
      }
    }
  }

  @media (max-width: 812px) and (orientation: landscape) {
    .v-block {
      &--adress {
        display: none;
      }
    }
  }

  @media (min-width: 992px) {
    .v-block {
      &--title {
        font-size: 6.5rem;
      }
      &--description {
        font-size: 3rem;
      }
      &--sr {
        font-size: 1.9875rem;
      }
      &--col {
      }
      &--footer {
        font-size: 1.25rem;
      }
    }
  }

  @media (min-width: 1200px) {
  }
`;

BlockCountry.defaultProps = {
  showLoginAction: () => {},
  showForgotPassAction: () => {},
};

const mapStateToProps = state => ({
  account: state.auth.account,
  isBlock: state.root.isBlock,
  language: state.root.language,
});

const mapDispatchToProps = {
  dispatchCheckGeoBlocking: () => checkGeoBlocking(),
  dpHideMenu: () => hideMenu(),
  dpShowMenu: () => showMenu(),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BlockCountry)),
);
