import { store } from '../store/index';
import moment from 'moment';
import { decodeToken } from 'react-jwt';
import { startFetch } from './fetch';
import { resetActiveIDs } from '../components/filter/filterSlice';
import { setIsShowLogin } from '../features/auth/actions';
import { logout } from '~features/auth/services';
import { history } from '../store/history';
import * as homeActions from '~features/homepage/actions';

const REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED';
const REFRESH_TOKEN_INVALID = 'REFRESH_TOKEN_INVALID';
const AUTHENTICATION_FAILED = 'authentication_failed';
let buffers = [];
let isRefreshing = false;

function addBuffer(buffer) {
  buffers.push(buffer);
}

function resetBuffers() {
  buffers = [];
}

function getBuffers() {
  return buffers;
}

function setIsRefreshing(value) {
  isRefreshing = value;
}

function getIsRefreshing() {
  return isRefreshing;
}

function checkLoginGuestAccount() {
  const auth = store.getState().auth;

  const guestAccount = auth.guestAccount;
  if (auth.isLogin || guestAccount) return true;
  return false;
}

function requestRefreshToken() {
  const auth = store.getState().auth;
  let account = auth.account;
  if (!account) account = auth.guestAccount;
  const refreshToken = account && account.refresh_token ? account.refresh_token : '';
  const path = '/backend/cas/refresh/';
  const headers = {
    Authorization: refreshToken,
  };
  return new Promise((resolve, reject) => {
    const dispatch = store.dispatch;
    startFetch(path, 'POST', headers, {})
      .then(response => {
        let actions = 'LOGIN_SUCCESS';
        if (auth.guestAccount) {
          actions = 'LOGIN_GUEST_SUCCESS';
        }
        dispatch({ type: actions, account: response });
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function isRequestRefreshToken() {
  const auth = store.getState().auth;
  let account = auth.account;
  let guestAccount = auth.guestAccount;
  if (auth && !auth.isLogin && account) {
    return false;
  }
  if (!account) account = guestAccount;
  if (!account) return false;
  const durationTime = 60 * 5;
  const timestamp = moment().unix();
  const dcToken = decodeToken(account.access_token || '');
  const exp = dcToken.exp || 0;
  const restExpireTime = exp - timestamp;
  if (!(restExpireTime < durationTime)) {
    return false;
  }
  return true;
}

function doneRefreshToken(response, error) {
  const buffers = getBuffers();
  const isRefreshFailed = !!(
    response === null &&
    error &&
    (error.error_code === REFRESH_TOKEN_EXPIRED ||
      error.error_code === REFRESH_TOKEN_INVALID ||
      error.error_code === AUTHENTICATION_FAILED)
  );
  if (isRefreshFailed) {
    const dispatch = store.dispatch;
    dispatch(logout());
    dispatch(homeActions.getFinal());
    dispatch(setIsShowLogin(true));
    history.push('/');
    return;
  }
  buffers.some(buffer => {
    const [fetchApi, options] = buffer;
    if (typeof fetchApi !== 'function') {
      return;
    }

    let opts = { ...options };
    let authorization = '';
    if (!isRefreshFailed) {
      authorization = response && response.access_token ? response.access_token : '';
    }
    opts = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: authorization,
      },
    };
    fetchApi(opts);
  });
  resetBuffers();
  setIsRefreshing(false);
}

function checkRefreshToken(buffer, { path, method, headers, body }) {
  const auth = store.getState().auth;

  if (typeof buffer !== 'function') {
    return;
  }

  const isRequest = isRequestRefreshToken();
  const isLogin = checkLoginGuestAccount();
  if (auth && isLogin && headers && (headers.Authorization || headers.authorization)) {
    if (isRequest && !isRefreshing) {
      setIsRefreshing(true);
      requestRefreshToken()
        .then(response => {
          doneRefreshToken(response, null);
        })
        .catch(error => {
          doneRefreshToken(null, error);
        });
    }
    if (isRefreshing) {
      addBuffer([buffer, { path, method, headers, body }]);
      return;
    }
  }
  buffer({ path, method, headers, body });
}

export { isRequestRefreshToken, doneRefreshToken, checkRefreshToken };
