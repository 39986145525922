import * as types from './actionTypes';

const initialState = {};

export default function metaTags(state = initialState, action = {}) {
  switch (action.type) {
    case types.UPDATE_SEO:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}
