import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';

const DivOverlayStyled = styled.div`
  ~ .overlay-loading {
    display: none !important;
  }
`;

function OverlayLoading(props) {
  const { type, customClass, color = '#FFFFFF' } = props;
  const [typeLoader, setTypeLoader] = useState('Oval');

  useEffect(() => {
    if (type) {
      setTypeLoader(type);
    }
  }, [type]);

  return (
    <DivOverlayStyled
      className={`${customClass || ''} overlay-loading`}
      style={{ ...styles.overlayLoading, position: props.position || 'fixed' }}
    >
      <Loader type={typeLoader} color={color} height="30" width="30" />
    </DivOverlayStyled>
  );
}

const styles = {
  overlayLoading: {
    width: '100%',
    height: 'calc(100% + 70px)',
    backgroundColor: '#000000',
    zIndex: 9999,
    opacity: 0.7,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    top: '-70px',
    left: 0,
    transition: '1s all',
  },
};

export default OverlayLoading;
