import { getConfigByKey } from '~features/tenantConfig/services';
import { tenantEnvConfig } from '../constants/tenantConfig/tenantEnvConfig';
import { ALL_LOCALES, getDefaultLocale } from '~i18n';

const defaultLocales = ['vi', 'en'];
const PROJECT_W_LOCALES = ['en', 'ko'];
const ESCONDIDO_LOCALES = ['en', 'es'];

export const getLocales = () => {
  let tenantLocales = getConfigByKey('locales');
  if (!tenantLocales) {
    const theme = tenantEnvConfig.ui_theme;
    if (theme === 'projectw') {
      tenantLocales = PROJECT_W_LOCALES;
    } else if (theme === 'escondido') {
      tenantLocales = ESCONDIDO_LOCALES;
    } else {
      tenantLocales = defaultLocales;
    }
  }

  if (typeof tenantLocales === 'string') {
    tenantLocales = tenantLocales.split(',');
  }

  return tenantLocales;
};

export const getLocaleFromUrl = (allowReturnEmpty = false) => {
  const regex = new RegExp(
    `^\/(${ALL_LOCALES.map(locale => `(?:${locale})`).join('|')})(?:.*)`,
    'g',
  );
  const { pathname } = window.location;
  const matchPathname = regex.exec(pathname || '') || '';
  const localeFromUrl = matchPathname.length === 2 ? matchPathname[1] : '';
  if (allowReturnEmpty) {
    return localeFromUrl;
  }
  return localeFromUrl || getDefaultLocale();
};
