import { createSlice } from '@reduxjs/toolkit';

const vodListPageSlice = createSlice({
  name: 'vodListPage',
  initialState: {
    page: {},
  },
  reducers: {
    setPage(state, action) {
      const value = action.payload;
      state.page = value;
    },
  },
});

export const { setPage } = vodListPageSlice.actions;

export default vodListPageSlice.reducer;
