import React from 'react';
import { ReactTitle } from 'react-meta-tags';
import { connect } from 'react-redux';
import { getConfigByKey } from '~features/tenantConfig/services';
import { withRouter, matchPath } from 'react-router';
const ROUTER_SEO_TEMPLATE = [
  '/:lg/ribbon/:slug',
  '/:lg/page/:slug',
  '/:lg/youtube/:slug',
  '/:lg/youtube/:slug/search',
  '/:lg/category/:slug',
];

const MetaTagSeo = props => {
  const { seo, location, match } = props;
  const [state, setState] = React.useState({
    match: false,
    pageLanding: false,
  });
  React.useEffect(() => {
    const arrayUrl = location.pathname.split('/');
    const regex = new RegExp('/:lg/((?:landing)|(?:detail))/:slug', 'g');
    const matchUrl = regex.exec(match.path || '');
    const currentRoute = ROUTER_SEO_TEMPLATE.map(route => {
      if ((arrayUrl.length === 3 && arrayUrl[2] === '') || arrayUrl.length === 2) return true;
      if (matchPath(location.pathname, route) === null) return false;
      return true;
    });
    setState({
      pageLanding: matchUrl ? true : false,
      match: currentRoute.find(route => route),
    });
  }, [location.pathname]);
  if (seo.title && state.match) return <ReactTitle title={seo.title} />;
  if (state.pageLanding) return null;
  return (
    <ReactTitle
      title={`Watch ${
        getConfigByKey('ui_theme') === 'projectw' ? '' : ' Vietnam '
      } Movies and Shows | ${getConfigByKey('site_name')}`}
    />
  );
};
const mapStateToProps = state => ({
  seo: state.metaTags,
});

export default withRouter(connect(mapStateToProps, null)(MetaTagSeo));
