import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useLocation } from 'react-router';

const Container = styled.div`
  border-radius: 3px;
`;

const Body = styled.div`
  height: calc((${props => props.size}px * ${props => props.lines} * 1.618));
  overflow: hidden;
  line-height: 1.618;
  word-break: break-word;
  padding: ${props => (props.lines ? '1em 0' : '0')};

  &.clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-pack: end;
  }

  &.open,
  &.closed {
    &:is(h1, h2, h3, h4, h5, h6, p) {
      margin: 0;
    }
    transition: height 0.3s ease-in-out, display 0.3s ease;
    transition-delay: 0, 1;
    * {
      transition: all 0.3s ease-out;
    }
  }
  &.open {
    display: block;
    font-size: 1em;
    height: calc((${props => props.openHeight}px + 1em));
  }

  &.closed {
    position: relative;
    -webkit-line-clamp: ${props => props.lines};
    text-overflow: ellipsis;
    /* * {
      font-size: 1em;
      margin-block-start: 0;
      margin-block-end: 0;
    } */
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background: linear-gradient(180deg, rgba(17, 17, 18, 0) 50%, rgba(17, 17, 18, 0.8) 100%);
    }
  }

  &.unset {
    transition: none;
    display: block;
    height: fit-content;
    font-size: 1em;
    * {
      margin: revert;
      margin-block-start: revert;
      margin-block-end: revert;
      transition: none;
    }
  }
`;

const ReadMoreButton = styled.button`
  color: blue;
  cursor: pointer;
  font-weight: bolder;
  text-decoration: none;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem 1rem;
`;

function useWindowSize() {
  const [size, setSize] = React.useState([window.innerWidth, window.innerHeight]);
  const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;
  useIsomorphicLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const Collapse = ({ seo, readMoreText, handleClick, isOpen }) => {
  const { t } = useTranslation();
  if (seo) {
    return (
      <div className="footer__seo--viewmore" onClick={handleClick}>
        {!isOpen ? (
          <React.Fragment>
            {t('txtReadMore')}
            <i className="fa fa-caret-down" aria-hidden="true" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {t('txtReadLess')}
            <i className="fa fa-caret-up" aria-hidden="true" />
          </React.Fragment>
        )}
      </div>
    );
  }
  return (
    <Footer>
      <ReadMoreButton onClick={handleClick}>{readMoreText}</ReadMoreButton>
    </Footer>
  );
};

export const ReadMore = ({ lines, children, seo = null }) => {
  const [openHeight, setOpenHeight] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [readMoreVisible, setReadMoreVisible] = React.useState(true);
  const [readMoreText, setReadMoreText] = React.useState('Read More');
  const [className, setClassName] = React.useState('closed clamp');
  // const [bodyRef, setBodyRef] = React.useState(null)
  const bodyRef = React.useRef(null);
  const location = useLocation();

  const [width] = useWindowSize();
  const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

  let size = 16;
  let countChirdren = seo
    ? React && React.Children.count(children) - 1
    : (React && React.Children.count(children)) || 1;
  const closedHeight = countChirdren * size * lines;
  const updateReadMoreText = pred => {
    const readMoreIndex = pred ? 'Read Less' : 'Read More';
    setReadMoreText(readMoreIndex);
  };
  const handleClick = () => {
    const nextOpenStatus = !isOpen;
    setIsOpen(nextOpenStatus);
    setReadMoreVisible(false);
    setClassName(nextOpenStatus ? 'open' : 'closed');
    updateReadMoreText(nextOpenStatus);
  };
  React.useEffect(() => {
    if (readMoreVisible) setReadMoreVisible(false);
    setClassName('closed');
  }, [location.pathname]);

  useIsomorphicLayoutEffect(() => {
    if (bodyRef.current) {
      const readMoreComponents = bodyRef.current.children;
      let componentScrollHeight = 0;
      for (let component of readMoreComponents) {
        componentScrollHeight += component.scrollHeight;
      }
      if (componentScrollHeight === 0) return;
      if (componentScrollHeight < closedHeight) {
        setReadMoreVisible(false);
        setClassName('unset');
      } else if (!readMoreVisible) {
        setReadMoreVisible(true);
        if (className === 'unset') {
          setClassName('closed');
          setIsOpen(false);
          updateReadMoreText(false);
        }
      }
      setOpenHeight(componentScrollHeight);
    }
  }, [bodyRef.current, width, closedHeight, readMoreVisible, className, seo]);

  return (
    <Container>
      <Body
        ref={bodyRef}
        lines={lines}
        size={size}
        openHeight={openHeight}
        className={classnames(
          // seo && seo.footer_block && 'footer__seo-more',
          className,
        )}
        onTransitionEnd={e => {
          e.target.classList.add('clamp');
        }}
      >
        {children}
        {/* <p className="txtDescription">{children}</p> */}
      </Body>
      {readMoreVisible && (
        <Collapse seo={seo} readMoreText={readMoreText} handleClick={handleClick} isOpen={isOpen} />
      )}
    </Container>
  );
};
