import React, { useState } from 'react';
import styled from 'styled-components';
import AdsBanner from './views';
import { getConfigByKey } from '~features/tenantConfig/services';
// import { motion } from 'framer-motion';

const FloatAdsStyled = styled.div`
  position: fixed;
  bottom: 0;
  /* width: 790px; */
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 998;
  min-height: 0;
  transition: transform 0.25s;
  background-color: #fff;
  .fa {
    transition: transform 0.25s;
  }
  &.hidden-float {
    transform: translate(-50%, 100%);
    .fa {
      transform: rotate(180deg);
    }
  }
  .v-ads-banner {
    padding: 0;
    margin: 0;
  }
  .toggle {
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 68px;
    height: 26px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 1.5rem;
    color: #666666;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    min-width: 728px;
  }
`;

const POSITION = 'FLOATING_BOTTOM';

function FloatAds() {
  const [toggle, setToggle] = useState(true);
  const floatBottom = (getConfigByKey('platformConfig.ads_banners') || []).find(
    ads => ads.position === POSITION,
  );

  if (!floatBottom) {
    return null;
  }
  const handleOnClick = () => {
    setToggle(!toggle);
  };
  return (
    <FloatAdsStyled className={`float-ads ${toggle ? '' : 'hidden-float'}`}>
      <AdsBanner
        toggleButton={
          <button className="toggle" onClick={handleOnClick}>
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          </button>
        }
        position={POSITION}
      />
    </FloatAdsStyled>
  );
}

export default FloatAds;
