import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
// import { App } from './app/views/index'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/index';
import { history } from './store/history';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import * as APIString from './constants/apiString';
import * as Version from './constants/envConfig';
import InitApp from './App';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './features/theme/globalStyles';
import { useTheme } from './features/theme/useTheme';
import { getConfigByKey } from './features/tenantConfig/services';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { CookiesProvider } from 'react-cookie';
import { insertScript } from '~root/app/functions/add-script';
import Loadable from 'react-loadable';
import { BrowserRouter } from 'react-router-dom';
const environment = APIString.REACT_APP_ENV;
if (Version.VERSION && environment) {
  Sentry.init({
    dsn: 'https://4ca7335d46ed4935b7c20c152a10dff5@o166441.ingest.sentry.io/5870387',
    environment,
    release: Version.VERSION,
  });
}

const mountElement = document.getElementById('root');
const reactMountFn = mountElement.childElementCount === 0 ? render : hydrate;
try {
  insertScript();
} catch (error) {}
window.onload = () => {
  Loadable.preloadReady().then(() => {
    reactMountFn(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <CookiesProvider>
              <BrowserRouter>
                <I18nextProvider i18n={i18n}>
                  <ThemeProvider theme={useTheme(tenantEnvConfig.ui_theme)}>
                    <GlobalStyles />
                    <InitApp />
                  </ThemeProvider>
                </I18nextProvider>
              </BrowserRouter>
            </CookiesProvider>
          </Router>
        </PersistGate>
      </Provider>,
      mountElement,
    );
  });
};
