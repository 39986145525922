import * as types from './actionTypes';

export { changeProfileSuccess, changeEmail, getProfile, updateHasSubscription };

function changeProfileSuccess(account) {
  return {
    type: types.UPDATE_PROFILE_SUCCESS,
    account,
  };
}

function changeEmail(email) {
  return {
    type: types.UPDATE_EMAIL,
    email,
  };
}

function getProfile(account) {
  return {
    type: types.GET_PROFILE,
    account,
  };
}

function updateHasSubscription(account) {
  return {
    type: types.UPDATE_ACCOUNT_SUBSCRIPTION,
    account,
  };
}
