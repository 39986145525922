import FETCH from '../utils/fetch';
import * as APIString from '../constants/apiString';
import { store } from '../store/index';
import queryString from 'query-string';

const loginSSO = (data = {}) => {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/logins/sso`;
  return FETCH(
    path,
    'POST',
    {
      'Content-Type': 'application/json',
      Authorization: data.sso_token,
    },
    JSON.stringify(data),
  );
};

const getPaidContent = (params = {}) => {
  const { account } = store.getState().auth;
  const { version = '' } = params;
  let Authorization = '';
  if (!account)
    return new Promise((resolve, reject) => {
      resolve();
    });
  const accountId = account.profile.id;
  Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/paid_contents${
    version ? `_${version}` : ''
  }/?${queryString.stringify(params)}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

const loginGoogle = (data = {}) => {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/logins/google`;
  return FETCH(
    path,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
};

const loginGuestAccount = () => {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/create_guest`;
  return FETCH(path, 'POST', {});
};

export { loginSSO, getPaidContent, loginGoogle, loginGuestAccount };
