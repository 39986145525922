import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getAdsBanner } from '../../../api/appAPIs';
import { REACT_APP_ROOT_PLATFORM_MENU_ID } from '../constants';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import uuid from 'react-uuid';
import classnames from 'classnames';
import jquery from 'jquery';
import { isMobile } from 'react-device-detect';

class AdsBanner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShow: true,
      adsBanner: null,
      adsBannerType: 'SCRIPT',
      iframeLoaded: false,
      interval: null,
      adsVerical: false,
      displayError: false,
      slotIsViable: false,
    };
    const { position } = this.props;
    this.SCRIPT = 'SCRIPT';
    this.GOOGLE_DFP = 'GOOGLE_DFP';
    this._id = uuid();
    this.position_id = `${position}_${this._id}`;
    this._count = 0;
    this.interval = null;
    this.timeRefreshAds = 45 * 1000;
    this.myRef = React.createRef();
  }

  componentWillMount() {
    const { auth, position } = this.props;
    if (
      auth &&
      auth.account &&
      auth.account.profile &&
      auth.account.profile.subscription_plan_info
    ) {
      this.setState({
        isShow: false,
      });
    }
    if (position === 'left' || position === 'right') {
      this.setState({
        adsVerical: true,
      });
    }
  }

  componentDidMount() {
    this.interval = window.setInterval(() => {
      DFPManager.refresh(this.position_id);
    }, this.timeRefreshAds);

    window.addEventListener('resize', this._autoScaleBanner.bind(this));
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('resize', this._autoScaleBanner.bind(this));
  }

  _handleOnSlotIsViable = dfpEventData => {
    this.setState({
      slotIsViable: true,
    });
    this._autoScaleBanner();
  };
  _autoScaleBanner = () => {
    const { position, rezise } = this.props;
    if (position === 'medium_rectangle' && rezise) {
      return;
    }
    if (!this.myRef.current) {
      return;
    }
    const containerAdsBanner = jquery(this.myRef.current).find(`#${this._id}`);
    const adunitContainer = containerAdsBanner.find('.adunitContainer');
    const adBox = containerAdsBanner.find('.adBox > div');
    const stylePropsAdBox = adBox.css(['width', 'height']);
    if (!adBox.length || !adunitContainer.length) {
      return;
    }

    const w = adBox.width();
    const h = adBox.height();

    const ctnW = jquery(window).width();

    let ratio = 1;
    let percent = 1;
    if (w > ctnW) {
      ratio = ctnW / w;
    } else {
      ratio = 1;
    }

    const newW = w * ratio;

    const newH = h * ratio;

    containerAdsBanner.width(newW).height(newH);
    adunitContainer.css({ transform: `scale(${ratio})` });
  };

  render() {
    const { adsVerical, displayError, slotIsViable } = this.state;
    const { position, nonMargin, customClass, auth, toggleButton, platformConfig } = this.props;
    if (!platformConfig || (platformConfig && !Array.isArray(platformConfig.ads_banners)))
      return null;
    if (auth.isHasSubcription || !this.state.isShow) return null;

    const arrBanner = platformConfig.ads_banners;
    const adsBannerConfig = arrBanner.find(element => element.position === position.toUpperCase());
    const adsBanner = this._getAdsBanner(adsBannerConfig);
    return (
      <>
        {slotIsViable && toggleButton && toggleButton}
        <DivStyled
          ref={this.myRef}
          className={classnames(
            'v-ads-banner',
            adsVerical ? 'v-detailPage__ads' : 'v-ads-banner__wrap',
            `v-ads-banner__${position}`,
            customClass,
          )}
          visible={!!adsBanner}
          ads={{
            padding: position === 'medium_rectangle',
            margin: nonMargin,
            displayError: displayError,
          }}
        >
          <div id={this._id} className="v-ads-banner--ads">
            {this._renderAdsBanner(adsBanner)}
          </div>
        </DivStyled>
      </>
    );
  }
  _renderAdsBanner = (bannerData = {}) => {
    const { adsBannerType, adsBanner } = bannerData || {};
    if (!adsBanner) {
      return null;
    }

    if (adsBannerType === this.SCRIPT) {
      jquery(`#${this._id}`).html(this.state.adsBanner);
      return null;
    }
    const {
      ads_banner_url,
      ads_provider: { dfp_network_id, dfp_ad_unit },
      position,
      sizes,
    } = adsBanner;

    return (
      <DFPSlotsProvider
        dfpNetworkId={dfp_network_id}
        collapseEmptyDivs
        autoLoad
        lazyLoad
        singleRequest
      >
        <AdSlot
          adUnit={dfp_ad_unit}
          slotId={this.position_id}
          sizes={this._getSize(sizes)}
          onSlotRender={eventData => {
            this._handleOnSlotIsViable();
            this.displayError(eventData);
          }}
          // onSlotIsViewable={this._handleOnSlotIsViable}
        />
      </DFPSlotsProvider>
    );
  };
  _getSize = size => {
    let result = [];
    if (!size) {
      return result;
    }
    let sizeParse = size;
    if (typeof size === 'string') {
      sizeParse = JSON.parse(size);
    }
    result = sizeParse.map((object, index) => {
      return [parseInt(object.width), parseInt(object.height)];
    });
    return result;
  };

  displayError = eventData => {
    const { position } = this.props;
    const adsErr = eventData.event.isEmpty;
    const slotId = eventData.slotId;
    // this._autoScaleBanner();
    this.setState({
      displayError: adsErr,
    });
    if (adsErr) {
      setTimeout(() => {
        DFPManager.refresh(slotId);
      }, 5000);
    }
  };
  _getAdsBanner = res => {
    if (!res) {
      return null;
    }
    const { ads_banner_url, ads_provider, position, sizes } = res;
    if (!ads_provider || jquery.isEmptyObject(ads_provider)) {
      return {
        adsBanner: ads_banner_url,
        adsBannerType: this.SCRIPT,
      };
    }
    let ads = null;
    const { dfp_ad_unit, dfp_network_id, type } = ads_provider;
    if (type === this.SCRIPT) {
      return {
        adsBanner: ads_banner_url,
        adsBannerType: this.SCRIPT,
      };
    }
    if (type === this.GOOGLE_DFP) {
      return {
        adsBanner: res,
        adsBannerType: this.GOOGLE_DFP,
      };
    }
  };
}
const DivStyled = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  position: relative;
  margin: ${props => (props.visible ? '1rem 0' : 0)};
  padding: ${props => (props.visible ? '0 20px' : 0)};
  min-height: ${props => (props.visible ? '1.5rem' : 0)};
  /* width: 970px;
  height: 100px; */
  &.v-ads-banner__medium_rectangle {
    height: 100%;
  }
  &.v-ads-banner__left,
  &.v-ads-banner__right {
    &:before {
      height: 600px;
    }
  }
  &:before {
    content: '\f127';
    background: ${props => `${props.ads.displayError ? `#dadada` : `none`}`};
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: FontAwesome;
    display: ${props => `${props.ads.displayError ? `flex` : `none`}`};
    justify-content: center;
    align-items: center;
    color: #8a8a8a;
    font-size: 1rem;
    opacity: 0.95;
  }
  .v-ads-banner {
    &--ads {
      z-index: 2;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      > div:first-of-type {
        transform-origin: top;
      }
    }
  }
  @media (min-width: 768px) {
    &.v-ads-banner__left,
    &.v-ads-banner__right {
      padding: 0;
    }
    padding: ${props => (props.ads.padding ? 0 : '0 35')}px;
  }
  @media (min-width: 992px) {
    .v-ads-banner {
      &--ads {
        overflow: unset;
      }
    }
  }

  @media (min-width: 1280px) {
  }
`;

const mapStateToProps = state => ({
  auth: state.auth,
  platformConfig: state.tenantConfig.platformConfig,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdsBanner));
