import * as types from './actionTypes';

const initialState = {
  favoriteList: [],
};

export default function myList(state = initialState, action = {}) {
  switch (action.type) {
    case types.UPDATE_MY_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
