import { firebase } from '../firebase/firebase';
import TagManager from 'react-gtm-module';
import { objectToSlug, getTenantConfig } from './../../utils/utils';
import { getConfigByKey } from '../../features/tenantConfig/services';

const pushAnalytics = (
  event,
  params = {},
  { toSlug = true, itemName = false } = { toSlug: true, itemName: false },
) => {
  let firebaseConfig = getConfigByKey('integration_config.firebase');
  if (firebaseConfig) firebaseConfig = JSON.parse(firebaseConfig.replace(/'/g, '"'));
  if (!firebaseConfig || (firebaseConfig && firebaseConfig.project_info)) {
    return;
  }
  if (firebase.apps.length === 0 || !getConfigByKey('feature_advanced_web_multitenancy_enabled')) {
    return;
  }
  let dataLayer = {
    event: event,
  };
  if (toSlug) {
    params = objectToSlug(params);
  }
  params = {
    ...params,
    app_platform: 'Web',
  };
  if (itemName) {
    dataLayer = { ...dataLayer, item_name: params.item_name || '' };
  }
  Object.assign(dataLayer, params);
  firebase.analytics().logEvent(event, params);
  const tenantConfig = getTenantConfig(getConfigByKey('tenant_slug'));
  if (tenantConfig && !tenantConfig.GTM) {
    return;
  }
  TagManager.dataLayer({
    dataLayer,
  });
};

export const pushAnalyticsNotSlug = (event, params) => {
  pushAnalytics(event, params, { toSlug: false, itemName: true });
};

export default pushAnalytics;
