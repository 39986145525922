import React from 'react';
import { Box } from '@material-ui/core';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import styled from 'styled-components';
import classnames from 'classnames';
const BoxStyled = styled(Box)`
  display: flex;
  .app {
    padding: 0 5px;
  }
  a {
    img {
      width: 120px;
      height: auto;
    }
  }
  @media (min-width: 768px) {
    a {
      img {
        width: 141px;
        height: auto;
      }
    }
  }
`;

export const DowloadApp = () => {
  const displayStyle = tenantEnvConfig.ui_theme;
  const appStore = '';
  return (
    <BoxStyled className="app-download">
      <a className="app" target="_blank" classnames={classnames(displayStyle !== 'odv' && ' hide')}>
        <img src={require(`~img/img/${appStore}googleTvIcon.svg`)} />
      </a>
      <a
        className="app"
        target="_blank"
        style={{ pointerEvents: displayStyle !== 'sctv' ? 'none' : 'auto' }}
        href={displayStyle === 'sctv' ? 'https://apps.apple.com/vn/app/sctv/id1564652065' : '/#'}
      >
        <img src={require(`~img/img/${appStore}appStoreIcon.svg`)} />
      </a>
      <a
        className="app"
        target="_blank"
        style={{ pointerEvents: displayStyle !== 'sctv' ? 'none' : 'auto' }}
        href={
          displayStyle === 'sctv'
            ? 'https://play.google.com/store/apps/details?id=io.vimai.sctvonline'
            : '/#'
        }
      >
        <img src={require(`~img/img/${appStore}googlePlayIcon.svg`)} />
      </a>
    </BoxStyled>
  );
};

export default DowloadApp;
