import * as types from './actionTypes';

export {
  loginSuccess,
  resetPassSuccess,
  resetPassFailure,
  RequestCodeSuccess,
  RequestCodeFailure,
  logout,
  updateAccountPaymentMethod,
  updateIP,
  setIsShowLogin,
  updateHasSubscription,
  updateAccountPaymentMethodPaypal,
  setPaidContents,
  resetPaymentMethod,
  rememberAccount,
  loginGuestAccount,
  removeGuestAccount,
  loginGuestSuccess,
};

function loginSuccess(account) {
  return {
    type: types.LOGIN_SUCCESS,
    account,
  };
}

function resetPassSuccess(reset) {
  return {
    type: types.RESETPASS_SUCCESS,
    reset,
  };
}

function resetPassFailure() {
  return {
    type: types.RESETPASS_FAILURE,
  };
}

function RequestCodeSuccess(otp) {
  return {
    type: types.REQUESTCODE_SUCCESS,
    otp,
  };
}

function RequestCodeFailure(error) {
  return {
    type: types.REQUESTCODE_FAILURE,
    error,
  };
}

function logout() {
  return {
    type: types.SIGNOUT_SUCCESS,
  };
}

function updateAccountPaymentMethod(payload) {
  return {
    type: types.UPDATE_ACCOUNT_PAYMENT_METHOD,
    payload,
  };
}
function updateAccountPaymentMethodPaypal(payload) {
  return {
    type: types.UPDATE_ACCOUNT_PAYMENT_METHOD_PAYPAL,
    payload,
  };
}

function updateIP(payload) {
  return {
    type: types.UPDATE_IP,
    payload,
  };
}

function setIsShowLogin(payload) {
  return {
    type: types.SET_IS_SHOW_LOGIN,
    payload,
  };
}
function updateHasSubscription(account) {
  return {
    type: types.UPDATE_ACCOUNT_SUBSCRIPTION,
    account,
  };
}

function setPaidContents(payload) {
  return {
    type: types.SET_PAID_CONTENT,
    payload,
  };
}

function resetPaymentMethod(account) {
  return {
    type: types.RESET_PAYMENT_METHOD,
    account,
  };
}

function rememberAccount(payload) {
  return {
    type: types.REMEMBER_ME,
    payload,
  };
}

function loginGuestAccount(account) {
  return {
    type: types.LOGIN_GUEST_ACCOUNT,
    account,
  };
}

function removeGuestAccount() {
  return {
    type: types.REMOVE_GUEST_ACCOUNT,
  };
}

function loginGuestSuccess(account) {
  return {
    type: types.LOGIN_GUEST_SUCCESS,
    account,
  };
}
