const _showForgotPassModal = component => {
  component.setState({
    showLoginModal: false,
    showRegisterModal: false,
    showResetPasswordModal: true,
    showMessagePopup: false,
  });
  component.props.dispatchSetIsShowLogin(false);
};

const _showLoginModal = (component, param = null) => {
  component.setState({
    showLoginModal: true,
    showRegisterModal: false,
    showResetPasswordModal: false,
    showMessagePopup: false,
    param: param,
  });
  component.props.dispatchSetIsShowLogin(true);
};

const _showRegisterModal = component => {
  component.setState({
    showLoginModal: false,
    showResetPasswordModal: false,
    showRegisterModal: true,
    showMessagePopup: false,
  });
  component.props.dispatchSetIsShowLogin(false);
};

const _hideModal = (component, key) => {
  if (key === 'showLoginModal') {
    component.props.dispatchSetIsShowLogin(false);
  }
  component.setState({
    [key]: false,
  });
};

const _showModal = key => {
  if (key === 'showLoginModal') {
    this.props.dispatchSetIsShowLogin(true);
  }
  this.setState({
    [key]: true,
  });
};

export { _showForgotPassModal, _showLoginModal, _showRegisterModal, _hideModal, _showModal };
