import * as API_STRING from '../constants/apiString';
import { store } from '../store/index';
import * as refreshToken from './refreshToken';
import { checkTenantConfigs } from './getSlugDomain';
import isomorphicFetch from 'isomorphic-fetch';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { isNode } from '~utils/ssr';
import * as utils from '~root/utils/utils';

import i18n from 'i18next';

export const controller = !isNode() ? new AbortController() : null;
const errorCustom = error => {
  let msg = error.message;
  if (typeof msg !== 'string' && Object.values(error.message).length) {
    msg = Object.values(error.message)
      .reduce((result, current) => {
        if (Array.isArray(current)) {
          current.map(message => {
            result.push(message);
          });
        } else {
          result.push(current);
        }

        return result;
      }, [])
      .join('\n');
    error.message = msg;
  }

  return error;
};

const getXTraceId = headers => {
  const { account } = store.getState().auth;
  let traceId = 'anonymous';
  if (account && account.profile && account.profile.id) {
    traceId = account.profile.id;
  }
  return Object.assign(headers, {
    'X-Trace-Account-Id': traceId,
  });
};

export default function FETCH(path, method, headers = {}, body) {
  if (headers.Authorization === null || headers.Authorization === '')
    headers.Authorization = undefined;
  return new Promise(function (resolve, reject) {
    const startF = ({ path, method, headers, body }) => {
      startFetch(path, method, headers, body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    };
    checkTenantConfigs(startF, { path, method, headers, body });
    // refreshToken.checkRefreshToken(startF, { path, method, headers, body })
  });
}

function pathUrl(path) {
  const { tenantSlug } = store.getState().root;
  const newPath = new RegExp(/tenants\/?(\/.*)?$/gm);
  let checkPath = newPath.test(path);
  let arrayPath = path.split('/');
  if (checkPath) {
    arrayPath.find((item, index) => {
      if (item === API_STRING.TENANT_SLUG) {
        arrayPath[index] = tenantSlug.tenant_slug;
        path = arrayPath.join('/');
        return true;
      }
    });
  }
  return path;
}
function startFetch(path, method, headers, body) {
  return new Promise((resolve, reject) => {
    let { language } = store.getState().root;
    const regLink = new RegExp('^https?.+');
    const ssrHeader = (utils.requestSsr && utils.requestSsr.customHeader) || null;
    const HEADERS = ssrHeader
      ? ssrHeader
      : {
          'Accept-Language': language || tenantEnvConfig.default_locale,
        };
    const timeout = setTimeout(() => {
      reject(new Error(i18n.t('requestApi.txtTimeOut')));
    }, 60000);

    const fetchURL = regLink.test(path) ? path : `${API_STRING.BASE_URL}${path}`;

    isomorphicFetch(
      fetchURL,
      {
        method,
        headers: {
          ...HEADERS,
          ...headers,
        },
        body: body || null,
      },
      !isNode() ? { signal: controller.signal } : {},
    )
      .then(response => {
        clearTimeout(timeout);
        if (response.status >= 400 && response.status < 500) {
          return response.json().then(data => {
            if (data && data.message) {
              const error = errorCustom(data);
              if (data.error_code === 'country_blocking_exception') {
                throw data;
              } else if (data.error_code === 'REFRESH_TOKEN_EXPIRED') {
                throw data;
              } else {
                throw error;
              }
            } else {
              throw new Error(i18n.t('requestApi.txtError'));
            }
            // throw new Error(JSON.stringify(data));
          });
        }
        if (response.status >= 500 && !response.ok) {
          reject(response);
        }
        return response.json();
      })
      .then(responseJson => {
        if (responseJson.error) {
          reject(responseJson);
        } else {
          resolve(responseJson);
        }
      })
      .catch(error => {
        if (error.message === 'Failed to fetch') {
          error.message = i18n.t('requestApi.txtError');
        }
        reject(error);
      });
  });
}

export { pathUrl, startFetch };
