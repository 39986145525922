import * as types from './actionTypes';

const initialState = {
  schedule: {
    ribbons: [],
    channels: [],
    epgs: [],
  },
  currentLive: {
    liveId: null,
    rewatchId: null,
    previewId: null,
    channelId: null,
    detail: null,
  },
  page: {
    search: '/',
  },
  activeRibbonId: null,
  volume: 0,
};

export default function home(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_LIVE_SCHEDULE:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          ribbons: action.payload.ribbons,
          channels: action.payload.channels,
          epgs: action.payload.episodes,
        },
      };
    case types.UPDATE_LIVE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case types.UPDATE_CURRENT_LIVE:
      return {
        ...state,
        currentLive: action.payload,
      };
    case types.SET_ACTIVE_RIBBON:
      return {
        ...state,
        activeRibbonId: action.id,
      };

    case types.SET_VOLUME_LIVE: {
      return {
        ...state,
        volume: action.payload,
      };
    }
    default:
      return state;
  }
}
