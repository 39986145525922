import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getConfigByKey } from '~features/tenantConfig/services';
import { withRouter, matchPath } from 'react-router';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReadMore } from '~components/readMore/views/line';
import { isMobileOnly } from 'react-device-detect';
const ROUTER_SEO_TEMPLATE = [
  '/:lg/ribbon/:slug',
  '/:lg/page/:slug',
  '/:lg/youtube/:slug',
  '/:lg/youtube/:slug/search',
  '/:lg/category/:slug',
];

const FooterSeo = props => {
  const { seo, location, isLoadingPage } = props;
  const { footer_block } = seo;
  const { t } = useTranslation();
  const [viewMore, setViewMore] = useState(false);
  const [state, setState] = React.useState({
    match: false,
  });
  React.useEffect(() => {
    const arrayUrl = location.pathname.split('/');
    const currentRoute = ROUTER_SEO_TEMPLATE.map(route => {
      if ((arrayUrl.length === 3 && arrayUrl[2] === '') || arrayUrl.length === 2) return true;
      if (matchPath(location.pathname, route) === null) return false;
      return true;
    });
    setState({
      match: currentRoute.find(route => route),
    });
    setViewMore(false);
  }, [location.pathname]);
  if (!footer_block || !state.match) return null;
  const paragraph = footer_block;
  const regex = /(<p>.+?<\/p>)/g;
  const regexStrRemoveP = /(<p[^>]+?>|<p>|<\/p>)/gim;
  const found = paragraph.match(regex);
  let title = '';
  let description = '';
  // if (!found)
  return (
    <DivStyled className={classnames('footer__seo')}>
      <ReadMore lines={isMobileOnly ? 0 : 7} limitHeight seo={seo}>
        <section
          className="footer__seo-first"
          dangerouslySetInnerHTML={{ __html: footer_block }}
        ></section>
      </ReadMore>
    </DivStyled>
  );

  found.map((items, index) => {
    if (!index) return (title = items);
    return (description = description + items.replace(regexStrRemoveP, '') + '. ');
  });
  return (
    <DivStyled className={classnames('footer__seo', viewMore && 'footer__seo-open')}>
      <section className="footer__seo-first" dangerouslySetInnerHTML={{ __html: title }} />
      {found.length > 1 ? (
        <ReadMore lines={2} seo={seo}>
          {found.map((items, index) => {
            if (!index) return null;
            return (
              <section
                className="footer__seo-more"
                key={index}
                dangerouslySetInnerHTML={{ __html: items }}
              />
            );
            if (!index)
              return (
                <section
                  className="footer__seo-first"
                  key={index}
                  dangerouslySetInnerHTML={{ __html: items }}
                />
              );
            if (index === 2 && !viewMore)
              return (
                <div className="footer__seo--viewmore" onClick={() => setViewMore(!viewMore)}>
                  {t('txtReadMore')}
                  <i className="fa fa-caret-down" aria-hidden="true" />
                </div>
              );
            if (index >= 2 && !viewMore) return null;
            return (
              <ReadMore lines={2} seo={seo}>
                {description}
              </ReadMore>
            );
            return (
              <section
                className="footer__seo-more"
                key={index}
                dangerouslySetInnerHTML={{ __html: items }}
              />
            );
          })}
        </ReadMore>
      ) : null}
      {viewMore ? (
        <div className="footer__seo--viewmore" onClick={() => setViewMore(!viewMore)}>
          {t('txtReadLess')}
          <i className="fa fa-caret-up" aria-hidden="true" />
        </div>
      ) : null}
    </DivStyled>
  );
};
const mapStateToProps = state => ({
  seo: state.metaTags,
});
const DivStyled = styled.div`
  &.footer__seo {
    padding: 0 15px;
    font-size: 10px;
    color: #9b9b9b;
    margin-bottom: 4.3em;
    transition: 1s all ease;
    &-open {
      color: #fff;
    }
    .open {
      .footer__seo-more {
        color: #fff;
      }
    }
  }

  .footer__seo {
    &-more,
    &-first {
      font-size: 1.4em;
      p {
        margin-bottom: 0.25em;
      }
    }
    &-first {
      /* color: #ffffff; */
      margin-bottom: 1.125em;
    }
    &--viewmore {
      width: 100%;
      text-align: center;
      color: ${({ theme }) => theme.primaryColor};
      font-size: 1.7em;
      cursor: pointer;
      margin-top: 1em;
      .fa-caret {
        &-up,
        &-down {
          padding-left: 0.58em;
          color: ${({ theme }) => theme.primaryColor};
        }
      }
    }
  }
  @media (min-width: 992px) {
    .footer__seo {
      &--viewmore {
        margin-top: 0.25em;
      }
    }
  }
`;

export default withRouter(connect(mapStateToProps, null)(FooterSeo));
