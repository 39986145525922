import * as types from './actionTypes';
import { getConfigByKey } from '../features/tenantConfig/services';
import { DEFAULT_LOCALE } from '~i18n';
import { isBrowser } from '~utils/ssr';
import { type } from 'jquery';

const subscriptionFeature = getConfigByKey('features.subscriptionFeature');
const initialState = {
  isAuth: false,
  language: DEFAULT_LOCALE,
  isBlock: false,
  tenantSlug: false,
  muxKey: null,
  isFollowSubcription: subscriptionFeature || false,
  subscriptionError: null,
};

export function root(state = initialState, action) {
  switch (action.type) {
    case types.INITIAL_APP:
      return {
        ...state,
      };
    case types.LOADING_LOCAL_STORAGE:
      return {
        ...state,
      };
    case types.AUTHENTICATE:
      return {
        ...state,
        isAuth: action.isAuth,
      };
    case types.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case types.COUNTRIES_BLOCK:
      return {
        ...state,
        isBlock: action.isBlock,
      };
    case types.UPDATE_TENANT_SLUG:
      return {
        ...state,
        tenantSlug: action.tenantSlug,
      };
    case types.UPDATE_MUX_KEY:
      return {
        ...state,
        muxKey: action.muxKey,
      };
    case types.UPDATE_REGION_SUBCRIPTION:
      if (!subscriptionFeature && isBrowser()) {
        return state;
      }
      return {
        ...state,
        isFollowSubcription: action.isFollowSubcription,
        subscriptionError: null,
      };
    case types.UPDATE_REGION_SUBCRIPTION_ERROR:
      return {
        ...state,
        subscriptionError: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
