import * as types from './actionTypes';

const initialState = {
  detail: {},
  season: {},
  pickRibbonRelative: {},
};

export default function landingPage(state = initialState, action = {}) {
  switch (action.type) {
    case types.UPDATE_LANDING_PAGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.REINIT_LANDING_PAGE: {
      return {
        ...state,
        detail: {},
        season: {},
      };
    }
    case types.UPDATE_SEASON_LANDING_PAGE: {
      return {
        ...state,
        season: { ...action.payload },
      };
    }
    case types.UPDATE_DETAIL_LANDING_PAGE: {
      return {
        ...state,
        detail: {
          ...action.payload,
        },
      };
    }
    case types.FETCH_PICK_RIBBON_RELATIVE: {
      return {
        ...state,
        pickRibbonRelative: action.payload,
      };
    }
    default:
      return state;
  }
}
