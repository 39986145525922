import FETCH from '../utils/fetch';
import * as APIString from '../constants/apiString';
import { store } from '../store/index';
import queryString from 'query-string';
import { getDeviceResolution, getAccessToken } from '~root/utils/utils';
import { getConfigByKey } from '~features/tenantConfig/services';
import * as landingPageApi from './landdingPageApi';

const CONTENT_RIBBON_DETAIL_SELECT_FIELDS = ['display_type', 'id', 'name', 'type', 'items', 'seo'];

const CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS = [
  'category_slug',
  'content_categories',
  'images',
  'is_new_release',
  'is_premium',
  'has_free_content',
  'id',
  'slug',
  'released_episode_count',
  'title',
  'top_index',
  'type',
  'video_source',
  'regions',
  'release_date',
  'released_episode_count',
  'total_episodes',
  'metadata',
  'runtime',
  'duration',
  'progress',
  'default_episode',
  'short_description',
  'long_description',
  'payment_type',
];

const CONTENT_VIEWS_SELECT_FIELDS = ['current_season'];
export const CONTENT_METADATA = ['regions', 'genres', 'sub_categories'];

const CONTENT_FAVORITE_SELECT_FIELDS = [
  'type',
  'released_episode_count',
  'total_episodes',
  'video_source',
  'images',
  'id',
  'slug',
  'title',
  'regions',
  'release_date',
  'released_episode_count',
  'top_index',
  'metadata',
  'duration',
  'short_description',
];

const CONTENT_SEARCH_YOUTUBE_PAGE = ['items', 'suggestion_items', 'keyword'];
const SEARCH_PAGE_CONTENT_ITEMS_SELECT_FIELDS = [
  'can_preview',
  'content_categories',
  'has_free_content',
  'id',
  'images',
  'is_new_release',
  'is_premium',
  'is_watchable',
  'num_first_episode_preview',
  'slug',
  'title',
  'type',
  'video_source',
  'payment_type',
];
const CONTENT_DETAIL_SELECT_FIELDS = [
  'short_description',
  'long_description',
  'images',
  'genre',
  'content_categories',
  'title',
  'title_en',
  'type',
  'eps',
  'metadata',
  'provider_slug',
];
const CONTENT_RIBBON_VOD = [
  'banners',
  'ribbons',
  'top_contents',
  'banner_style',
  'display_style',
  'side_navigation_section',
  'ribbons_in_subcategory_section',
  'seo',
  'name',
];

function getRibbonDetail(pageSlug, ribbonSlug, search = null) {
  const select = JSON.stringify({
    RibbonDetailPaging: CONTENT_RIBBON_DETAIL_SELECT_FIELDS,
    Content: CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS,
    ContentMetadata: CONTENT_METADATA,
  });
  let data = { ...queryString.parse(search), ...getDeviceResolution(), select };

  const queryParams = queryString.stringify(data);
  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_pages/${pageSlug}/ribbons/${ribbonSlug}/?${queryParams}`;
  return FETCH(path, 'GET', {});
}

function searchYoutubePage(params, pageSlug) {
  const select = JSON.stringify({
    Search: CONTENT_SEARCH_YOUTUBE_PAGE,
    YOUTUBE: SEARCH_PAGE_CONTENT_ITEMS_SELECT_FIELDS,
  });
  let search = encodeURIComponent(params.keyword);
  let data = { ...params, ...getDeviceResolution() };
  const queryParams = queryString.stringify({ ...data, select });
  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_page/${pageSlug}/search_contents/?q=${search}&${queryParams}`;
  return FETCH(path, 'GET', {});
}

function getRibbonSearchContent(pageSlug, search = null) {
  const { account } = store.getState().auth;
  let Authorization = '';
  const data = { ...queryString.parse(search), ...getDeviceResolution() };
  const queryParams = queryString.stringify(data);
  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_pages/${pageSlug}/tenant_page/?${queryParams}`;

  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }

  return FETCH(path, 'GET', {
    Authorization,
  });
}

function getRibbonDetailRecent(pageSlug, ribbonSlug, search = null) {
  const { account, guestAccount } = store.getState().auth;
  let authorization = getAccessToken();
  const accountId =
    (account && account.profile.id) || (guestAccount && guestAccount.profile.id) || null;
  const select = JSON.stringify({
    RibbonDetailPaging: CONTENT_RIBBON_DETAIL_SELECT_FIELDS,
    Content: CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS,
  });
  let data = { ...queryString.parse(search), ...getDeviceResolution(), select };
  const queryParams = queryString.stringify(data);
  if (accountId) {
    const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/tenant_pages/${pageSlug}/personalized_ribbons/${ribbonSlug}/?${queryParams}`;
    return FETCH(path, 'GET', {
      authorization,
    });
  }
}

function deleteProgress(contentId) {
  let authorization = getAccessToken() || '';
  if (!authorization) {
    return;
  }
  const path = `/backend/cm/content/${contentId}/progress`;
  return FETCH(path, 'DELETE', {
    authorization,
    'Content-Type': 'application/json',
  });
}

function getTopTen(pageSlug) {
  const { account } = store.getState().auth;
  let Authorization = null;
  let param = '';
  const select = JSON.stringify({
    Content: CONTENT_FAVORITE_SELECT_FIELDS,
    ContentMetadata: CONTENT_METADATA,
  });
  let data = { ...getDeviceResolution(), select };

  const queryParams = queryString.stringify(data);
  if (pageSlug) {
    param = `tenant_page_slug=${pageSlug}`;
  }
  if (account) {
    Authorization = account.access_token ? account.access_token : null;
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/top_contents/?${queryParams}&${param}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function getDetailContentById(contentId) {
  return landingPageApi.viewDetail(contentId);
}

export function getViewContentsByIds(ids = []) {
  const { account } = store.getState().auth;
  let Authorization = null;
  let param = '';
  const select = JSON.stringify({
    Content: ['link_play', 'id', 'trailer_urls', 'program_urls'],
  });
  let data = { ...getDeviceResolution(), select, ids: JSON.stringify(ids) };

  const queryParams = queryString.stringify(data);
  if (account) {
    Authorization = account.access_token ? account.access_token : null;
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/view?${queryParams}&${param}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function getRibbonVOD(pageSlug, configs) {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const select = JSON.stringify({
    RibbonDetailPaging: CONTENT_RIBBON_VOD,
    Content: CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS,
    ContentMetadata: CONTENT_METADATA,
  });
  let data = { ...getDeviceResolution(), select };
  const queryParams = queryString.stringify(data);
  const path = `/tenants/${
    APIString.TENANT_SLUG
  }/tenant_pages/${pageSlug}/ribbons/?${queryParams}&platform_slug=${getConfigByKey(
    'platform_slug',
  )}`;
  return FETCH(path, 'GET', {});
}

function getRibbonVOD_Personalized(pageSlug) {
  const { account } = store.getState().auth;
  let Authorization = '';
  const select = JSON.stringify({
    RibbonDetailPaging: CONTENT_RIBBON_VOD,
    Content: CONTENT_RIBBON_DETAIL_ITEMS_SELECT_FIELDS,
    ContentMetadata: CONTENT_METADATA,
  });
  let data = { ...getDeviceResolution(), select };
  const queryParams = queryString.stringify(data);
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
    const accountId = account.profile.id;
    const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/tenant_pages/${pageSlug}/personalized_ribbons/?${queryParams}`;
    return FETCH(path, 'GET', {
      Authorization,
    });
  }
}

function getViews(slug) {
  const select = JSON.stringify({
    Content: CONTENT_VIEWS_SELECT_FIELDS,
  });
  let data = { select };
  const queryParams = queryString.stringify(data);
  let authorization = getAccessToken() || '';
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${slug}/view?${queryParams}`;
  return FETCH(path, 'GET', {
    authorization,
  });
}

export {
  getRibbonDetail,
  deleteProgress,
  getRibbonDetailRecent,
  getTopTen,
  getRibbonSearchContent,
  searchYoutubePage,
  getDetailContentById,
  getRibbonVOD,
  getRibbonVOD_Personalized,
  getViews,
};
