import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import vi from './locales/vi.json';
import ko from './locales/ko.json';
import es from './locales/es.json';
import en_devel from './locales/en_devel.json';

import { getConfigByKey } from '~features/tenantConfig/services';
import { getLocales } from '~utils/locales';
import { matchPath } from 'react-router';
import { history } from '~store/history';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { localStorage as ls } from 'js-storage';

export const getDefaultLocale = () => {
  let persistRoot = '';
  let language = '';
  try {
    persistRoot = ls.get('persist:root');
  } catch (error) {}
  if (typeof languageStoreRoot === 'string') {
    persistRoot = JSON.parse(persistRoot);
  }
  if (persistRoot && persistRoot.root) {
    language = JSON.parse(persistRoot.root);
    language = language.language;
  }
  const lngStore = persistRoot ? language : '';
  if (AVAILABLE_LOCALES.includes(lngStore)) {
    return lngStore;
  }
  const theme = tenantEnvConfig.ui_theme;
  return (
    getConfigByKey('default_locale') ||
    (theme === 'projectw' ? 'ko' : 'en') ||
    (theme === 'escondido' ? 'es' : 'en')
  );
};

export const ALL_LOCALES = ['vi', 'en', 'ko', 'es'];
export const AVAILABLE_LOCALES = getLocales();
export const DEFAULT_LOCALE = getDefaultLocale();

const location = window.location;

const match = matchPath(location.pathname, {
  path: `/:lang(${ALL_LOCALES.join('|')})/:rest(.*)`,
  exact: true,
  strict: false,
});

if (match && !AVAILABLE_LOCALES.includes(match.params.lang)) {
  history.replace(`/${DEFAULT_LOCALE}/${match.params.rest}${history.location.search}`);
}

const defaultLng = DEFAULT_LOCALE;
let language = defaultLng;
i18n.use(initReactI18next).init({
  lng: language,
  resources: {
    en: {
      translations: en,
    },
    vi: {
      translations: vi,
    },
    ko: {
      translations: ko,
    },
    es: {
      translations: es,
    },
    en_devel: {
      translations: en_devel,
    },
  },
  fallbackLng: {
    default: ['en', 'en_devel'],
  },
  debug: false,

  ns: ['translations'],
  defaultNS: 'translations',
  compatibilityJSON: 'v2',
  interpolation: {
    escapeValue: false,
    formatSeparator: '.',
  },

  react: {
    wait: true,
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: false,
  },
});

export default i18n;
