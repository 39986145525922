export const INITIAL_HOME_SCREEN = 'INITIAL_HOME_SCREEN';
export const GET_MENU = 'GET_MENU';
export const GET_PAGE = 'GET_PAGE';
export const GET_DETAIL = 'GET_DETAIL';
export const REFRESH_PAGE = 'REFRESH_PAGE';
export const UPDATE_LANDING_PAGE = 'UPDATE_LANDING_PAGE';
export const UPDATE_DETAIL_LANDING_PAGE = 'UPDATE_DETAIL_LANDING_PAGE';
export const UPDATE_SEASON_LANDING_PAGE = 'UPDATE_SEASON_LANDING_PAGE';
export const REINIT_LANDING_PAGE = 'REINIT_LANDING_PAGE';
export const FETCH_PICK_RIBBON_RELATIVE = 'FETCH_PICK_RIBBON_RELATIVE';
