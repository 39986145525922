export const BASE_URL = process.env.REACT_APP_CORE_SERVICE_BASE_URL;
export const ROOT_PLATFORM_MENU_ID = '{ROOT_PLATFORM_MENU_ID}';
export const ROOT_PLATFORM_MENU_ID_DEFAULT = process.env.REACT_APP_ROOT_PLATFORM_MENU_ID;
export const ROOT_PAGE_ID = process.env.REACT_APP_ROOT_PAGE_ID;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const TENANT_ID = process.env.REACT_APP_TENANT_ID;
export const TENANT_SLUG = '{TENANT_SLUG}';
export const TENANT_SLUG_DEFAULT = 'no-tenant';
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const COMMING_SOON = process.env.REACT_APP_COOMINGSOON;
export const EMAIL_CONFIRMATION = process.env.REACT_APP_EMAIL_CONFIRMATION;
export const REACT_APP_FIREBASE_APIKEY = process.env.REACT_APP_FIREBASE_APIKEY;
export const REACT_APP_FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const REACT_APP_FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const REACT_APP_FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const REACT_APP_FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const REACT_APP_FIREBASE_MESSAGIN_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGIN_SENDER_ID;
export const REACT_APP_FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const REACT_APP_FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const REACT_APP_ONESIGNAL_APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID;
export const REACT_APP_ONESIGNAL_SAFARI_WEB_ID = process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID;
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const REACT_APP_TENANT_PLATFORM = process.env.REACT_APP_TENANT_PLATFORM;
export const REACT_APP_GG_CLIENT_ID = process.env.REACT_APP_GG_CLIENT_ID;
export const REACT_APP_REFERRALS_ENCRYPTED_KEY = process.env.REACT_APP_REFERRALS_ENCRYPTED_KEY;
export const REACT_APP_REFERRALS_BRAND_ID = process.env.REACT_APP_REFERRALS_BRAND_ID;
export const REACT_APP_DATA_DOMAIN_SCRIPT = process.env.REACT_APP_DATA_DOMAIN_SCRIPT;
