import * as appAPIs from '../../api/appAPIs';
import { updateMyList } from './actions';

export { getFavorite, postFavorite };
function getFavorite() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .getFavorite()
        .then(response => {
          dispatch(updateMyList({ favoriteList: response.items }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function postFavorite(idContent) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .postFavorite(idContent)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}
