import * as types from './actionTypes';

const initialState = {
  isLogin: false,
  account: null,
  resetpass: null,
  methodLogin: null,
  locale: null,
  isShowLogin: false,
  paidContents: [],
  isHasSubcription: false,
  rememberAccount: {
    email: null,
    checkbox: false,
  },
  guestAccount: null,
};

export function auth(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        account: typeof action.account === 'object' ? action.account : null,
        methodLogin: action.methodLogin || null,
        isLogin: true,
        isShowLogin: false,
        isHasSubcription:
          typeof action.account === 'object' ? action.account.profile.has_subscription : false,
      };
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        account: {
          ...state.account,
          profile: action.account,
        },
      };
    case types.SIGNOUT_SUCCESS:
      return {
        ...state,
        account: null,
        isLogin: false,
        paidContents: [],
        isHasSubcription: false,
      };

    case types.RESETPASS_SUCCESS:
      return {
        ...state,
        resetpass: {
          otp: action.otp,
          reset: action.reset,
        },
      };
    case types.RESETPASS_FAILURE:
      return {
        ...state,
        resetpass: {
          otp: action.otp,
          reset: false,
        },
      };

    case types.REQUESTCODE_SUCCESS:
      return {
        ...state,
        resetpass: {
          otp: action.otp,
        },
      };
    case types.REQUESTCODE_FAILURE:
      return {
        ...state,
        resetpass: {
          otp: action.error,
        },
      };
    case types.REGISTER_LOGIN_SUCCESS:
      return {
        ...state,
        account: action.account,
        isLogin: true,
        methodLogin: action.methodLogin || null,
      };
    case types.UPDATE_EMAIL:
      return {
        ...state,
        account: {
          ...state.account,
          profile: {
            ...state.account.profile,
            email: action.email,
            email_confirmed: false,
          },
        },
      };
    case types.UPDATE_FIRSTNAME:
      return {
        ...state,
        account: {
          ...state.account,
          profile: {
            ...state.account.profile,
            first_name: action.first_name,
          },
        },
      };
    case types.GET_PROFILE:
      return {
        ...state,
        account: {
          ...state.account,
          profile: action.account,
        },
      };

    case types.UPDATE_ACCOUNT_SUBSCRIPTION:
      const account = state.account;
      return {
        ...state,
        account: account
          ? {
              ...state.account,
              profile: {
                ...state.account.profile,
                has_subscription: action.account.has_subscription,
              },
            }
          : account,
        isHasSubcription: action.account.has_subscription,
      };
    case types.UPDATE_ACCOUNT_PAYMENT_METHOD:
      return {
        ...state,
        account: {
          ...state.account,
          paypalMethod: [],
          paymentMethod: action.payload,
        },
      };
    case types.UPDATE_ACCOUNT_PAYMENT_METHOD_PAYPAL:
      return {
        ...state,
        account: {
          ...state.account,
          paymentMethod: [],
          paypalMethod: action.payload,
        },
      };
    case types.UPDATE_IP:
      return {
        ...state,
        locale: {
          ip: action.payload,
        },
      };
    case types.SET_IS_SHOW_LOGIN:
      return {
        ...state,
        isShowLogin: action.payload,
      };
    case types.SET_PAID_CONTENT:
      return {
        ...state,
        paidContents: action.payload,
      };
    case types.RESET_PAYMENT_METHOD:
      return {
        ...state,
        account: null,
      };
    case types.REMEMBER_ME:
      return {
        ...state,
        rememberAccount: action.payload,
      };

    case types.LOGIN_GUEST_ACCOUNT:
      return {
        ...state,
        guestAccount: action.account,
      };
    case types.REMOVE_GUEST_ACCOUNT:
      return {
        ...state,
        guestAccount: null,
      };
    case types.LOGIN_GUEST_SUCCESS:
      return {
        ...state,
        guestAccount: typeof action.account === 'object' ? action.account : null,
      };
    default:
      return state;
  }
}
