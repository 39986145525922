import { getConfigByKey } from '~features/tenantConfig/services';
import jquery from 'jquery';
import SmartBanner from 'smart-app-banner';

const setScript = (key = null) => {
  let script = document.createElement('script');
  if (key) {
    script.innerHTML = `function OptanonWrapper() {}`;
    document.head.appendChild(script);
    return;
  }
  script.type = 'text/javascript';
  script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  script.setAttribute('defer', 'defer');
  script.setAttribute('charset', 'UTF-8');
  script.setAttribute(
    'data-domain-script',
    `${getConfigByKey('integrations.cookielaw.data_domain_script')}`,
  );
  document.head.appendChild(script);
};
const insertScript = () => {
  if (window.ir) {
    return;
  }
  if (!getConfigByKey('feature_advanced_web_multitenancy_enabled')) {
    return;
  }
  const body = jquery('body');
  let html = '';

  if (getConfigByKey('features.cookielaw')) {
    // OneTrust is implemented with a <script> tag in the <head> of the webpage, above other scripts since consent is necessary before other scripts load and potentially set cookies.
    //  <!-- OneTrust Cookies Consent Notice start -->
    setScript();
    setScript(true);
    // <!-- OneTrust Cookies Consent Notice end -->
  }

  if (getConfigByKey('features.ABTesting') && getConfigByKey('integrations.optimizeGg')) {
    let data = getConfigByKey('integrations.optimizeGg');
    let scripts = document.createElement('script');
    scripts.src = `https://www.googleoptimize.com/optimize.js?id=${data.idContainer}`;
    document.head.appendChild(scripts);

    // let script = document.createElement('script')
    // script.type = 'text/javascript'
    // script.src = `https://www.googletagmanager.com/gtag/js?id=${data.analyticsMeasurementId}`
    // script.async = true
    // document.head.appendChild(script)

    // let script2 = document.createElement('script')
    // script2.innerHTML = `
    // window.dataLayer = window.dataLayer || []
    //   function gtag() {
    //     dataLayer.push(arguments)
    //   }
    //   gtag('js', new Date())

    //   gtag('config', '${data.analyticsMeasurementId}')
    // `
    // document.head.appendChild(script2)
  }
  if (getConfigByKey('features.invite_referrals')) {
    const { bid_e, bid } = getConfigByKey('integrations.invite_referrals');
    html += `
      <div id='invtrflfloatbtn'></div>
      <script>
        var ir = ir || function () { (window.ir.q = window.ir.q || []).push(arguments) };
        var invite_referrals = window.invite_referrals || {}; (function () {
          invite_referrals.auth = {
            bid_e: "${bid_e}",
            bid: "${bid}",
            t: '420',
            email: '', userParams: { 'fname': '' }
          };
          invite_referrals.async = false;
          var script = document.createElement('script');
          script.defer = true;
          script.src = "//cdn.invitereferrals.com/js/invite-referrals-1.0.js";
          var entry = document.getElementsByTagName('script')[0]; entry.parentNode.insertBefore(script, entry);
        })();
      </script>
    `;
  }
  if (getConfigByKey('features.smartBanner')) {
    new SmartBanner({
      daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
      daysReminder: 1, // days to hide banner after "VIEW" button is clicked (defaults to 90)
      appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
      title: getConfigByKey('site_name'),
      author: getConfigByKey('site_name'),
      button: 'VIEW',
      store: {
        ios: 'On the App Store',
        android: 'In Google Play',
        windows: 'In Windows store',
      },
      price: {
        ios: 'FREE',
        android: 'FREE',
        windows: 'FREE',
      },
    });
  }

  body.append(html);
};

export { insertScript };
