import React, { Component } from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';

const DivStyled = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  span {
    background-color: ${({ theme }) => theme.spinner.color};
  }
`;

export default class PageLoading extends Component {
  render() {
    return (
      <DivStyled>
        <Spinner />
      </DivStyled>
    );
  }
}
