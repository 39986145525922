import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import { VERSION, DEBUG } from '../constants/envConfig';
import { createTransform } from 'redux-persist';
import { storeSubscribe } from '~features/tenantConfig/services';
import { REACT_APP_SSR } from '~constants/envConfig';
import { initialState as initStateHome } from '~features/homepage/reducers';
import { isNode } from '~utils/ssr';

const version = VERSION.split('.').pop() || -1;

const isSsrHomepage = window.__STATE__ ? window.__STATE__.home.isSsr : null;

const transformHome = (boundState, key, initState = null) => {
  if (key !== 'home' || !initState || (key === 'home' && isSsrHomepage)) {
    return { ...boundState };
  }
  return {
    ...boundState,
    isLoadingPage: initState.isLoadingPage,
  };
};

const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // convert mySet to an Array.

    return { ...inboundState };
  },
  // transform state being rehydrated
  (outboundState, key) => {
    // convert mySet back to a Set.
    const newBoundState = transformHome(outboundState, key, initStateHome);

    return { ...newBoundState };
  },
  // define which reducers this transform gets called for.
  { whitelist: isSsrHomepage ? [] : ['home'] },
);

const loggerMiddleware = createLogger({
  predicate: (getState, action) => DEBUG === 'true',
});
const migrations = {};
migrations[version] = state => {
  // migration to keep only auth state
  return {
    auth: state.auth,
  };
};

const BLACK_LIST_SSR = isSsrHomepage ? ['home', 'metaTags'] : [];
const config = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['backdrop', 'tenantConfig', 'blockCountry', ...BLACK_LIST_SSR],
  version: version,
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [SetTransform],
};

const persistedReducer = persistReducer(config, rootReducer);
const initState = window.__STATE__ || {};
if (!DEBUG) {
  delete window.__STATE__;
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  persistedReducer,
  initState,
  composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware)),
);
export const persistor = persistStore(store, window.PRELOADED_STATE);

const unsubscribe = store.subscribe(() => {
  storeSubscribe(store);
});
