import * as appAPIs from '../../api/appAPIs';
import { store } from '../../store/index';
import { setNewState } from './blockingSlice';
import moment from 'moment';
import { backOff } from 'exponential-backoff';

const checkBlockCountry = (req = null) => {
  return async dispatch => {
    const { envConfig: envConfigReq = {} } = req || {};

    const storeRedux = req ? req.store : store;
    const dpStore = req ? req.store.dispatch : dispatch;
    const { loaded, isBlock, expire, duration } = storeRedux.getState().blockCountry;
    const now = moment().unix();

    // if (loaded && expire >= now) {
    if (loaded) {
      if (!isBlock) {
        return Promise.resolve();
      }
      return Promise.reject();
    }

    const options = {
      numOfAttemps: 10,
      timeMultiple: 2,
      startingDelay: 2000,
    };
    let tenantSlug = global && global.envConfig && global.envConfig.tenant_slug;
    if (!tenantSlug) {
      tenantSlug = envConfigReq.tenantSlug;
    }
    if (!tenantSlug) {
      tenantSlug =
        storeRedux.getState().tenantConfig && storeRedux.getState().tenantConfig.tenant_slug;
    }

    try {
      let response = {};
      if (tenantSlug) {
        response = await appAPIs.allowedCountries(tenantSlug);
      } else {
        response = await backOff(() => appAPIs.allowedCountries(), options);
      }
      // process response
      const { isBlock, result } = response;
      return new Promise((resolve, reject) => {
        if (!isBlock) {
          dpStore(
            setNewState({
              loaded: true,
              loading: false,
              isBlock: false,
              expire: now + duration,
            }),
          );
          resolve(result);
        } else {
          dpStore(
            setNewState({
              loaded: true,
              loading: false,
              isBlock: true,
            }),
          );
          reject(new Error('Block country'));
        }
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };
};
export { checkBlockCountry };
